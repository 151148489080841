import React, { useState, useRef } from 'react'
import ReactMarkdown from 'react-markdown'
import HashLink from '../../utils/HashLink'

const ParagraphWithForm = ({ data }) => {
	let border_top = ''
	let spacing = ''
	if (data.TopGreyLine !== false) {
		border_top = 'border-top-light-grey'
	}
	if (data.SpacingTop === false) {
		spacing += 'spacing-top-0'
	}
	if (data.SpacingBottom === false) {
		spacing += ' spacing-bottom-40'
	}

	const FORMSPARK_ACTION_URL = `https://submit-form.com/${data.FormsparkID}`

	const [sending, setSending] = useState(false)
	const [success, setSuccess] = useState(false)
	const [error, setError] = useState(false)

	const originalFormState = {
		_email: {
			subject: data.SubjectLine,
		},
	}

	const form = useRef(null)

	const [formData, setFormData] = useState(originalFormState)

	function handleChange(e) {
		const key = e.target.name
		let value = e.target.value
		setFormData((prev) => ({
			...prev,
			[key]: value,
		}))
	}

	function handleSubmit(e) {
		e.preventDefault()
		setSending(true)

		fetch(FORMSPARK_ACTION_URL, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(formData),
		})
			.then((res) => {
				setSending(false)
				setSuccess(true)
			})
			.catch((err) => {
				setSending(false)
				setError(true)
				console.error('this is the error', err)
			})
	}
	return (
		<section
			className={`paragraph ${border_top} ${spacing} m-spacing-bottom-40`}
			data-section-id="paragraph-with-form"
		>
			<HashLink hashId={data.HashLink} />
			<div className="gc">
				{data.Title ? (
					<div className="d-1-7 m-1-13">
						<h3>{data.Title}</h3>
					</div>
				) : null}
				<div className="d-7-13 m-1-13">
					<div className="auto-layout auto-layout-20">
						{data.Paragraph && <ReactMarkdown>{data.Paragraph}</ReactMarkdown>}
					</div>
					{error ? (
						<div className="mt-8">
							<p>Sorry there has been an error - please refresh</p>
						</div>
					) : (
						<>
							{success ? (
								<div className="mt-8">
									<p>
										Thanks! Your form has been submitted, we willl be in touch
										shortly.
									</p>
								</div>
							) : (
								<form
									onSubmit={(e) => handleSubmit(e)}
									className="auto-layout auto-layout-20 mt-16 w-80 m-w-100"
									ref={form}
								>
									{data.FormFields &&
										data.FormFields.map((field) => (
											<div>
												<label>{field.Label}</label>
												<FormField {...field} onChange={handleChange} />
											</div>
										))}
									{sending ? (
										<button disabled class="primary">
											Submitting
										</button>
									) : (
										<button type="submit" class="primary">
											Submit
										</button>
									)}
								</form>
							)}
						</>
					)}
				</div>
			</div>
		</section>
	)
}

export default ParagraphWithForm

const FormField = ({ Label, Type, Required, onChange }) => {
	return Required ? (
		<input
			onChange={(e) => onChange(e)}
			required
			className="w-100"
			name={Label.replaceAll(' ', '-')}
			type={Type}
		/>
	) : (
		<input
			className="w-100"
			onChange={(e) => onChange(e)}
			name={Label.replaceAll(' ', '-')}
			type={Type}
		/>
	)
}
