import React, { useState, useEffect } from 'react'

const RsvpYes = ({ data }) => {
	/* Here’s the juicy bit for posting the form submission */

	const FORMSPARK_ACTION_URL = 'https://submit-form.com/v2U3dYC6x'

	const [sending, setSending] = useState(false)
	const [success, setSuccess] = useState(false)
	const [error, setError] = useState(false)

	const [formData, setFormData] = useState({
		first_name: '',
		last_name: '',
		email: '',
		company: '',
		dietary_requirements: false,
		dietary_requirements_details: '',
		accessibility_requirements: false,
		accessibility_requirements_details: '',
	})

	function handleChange(e) {
		const key = e.target.name
		let value = e.target.value

		if (key == 'dietary_requirements' || key == 'accessibility_requirements') {
			value = e.target.value === 'true'
		}

		setFormData((prev) => ({
			...prev,
			[key]: value,
		}))
	}

	function handleSubmit(e) {
		e.preventDefault()
		setSending(true)

		fetch(FORMSPARK_ACTION_URL, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(formData),
		})
			.then((res) => {
				setSending(false)
				setSuccess(true)
			})
			.then(() => {
				setTimeout(() => {
					window.location.href = '/'
				}, 4000)
			})
			.catch((err) => {
				setSending(false)
				setError(true)
				console.error('this is the error', err)
			})
	}

	return (
		<section
			data-section-id="news-index"
			className="m-spacing-top-20 border-top-light-grey"
		>
			<form class="gc" onSubmit={(e) => handleSubmit(e)}>
				<input type="hidden" name="_email.subject" value="RSVP YES" />
				<div className="d-1-7 m-1-13 auto-layout">
					<h3>{data?.Title && data.Title}</h3>
				</div>
				<div className="d-7-12 m-1-13 auto-layout">
					<p>
						<label>
							First Name{' '}
							<input
								type="text"
								name="first_name"
								className="w-100"
								onChange={(e) => handleChange(e)}
							/>
						</label>
					</p>
					<p>
						<label>
							Last Name
							<input
								type="text"
								name="last_name"
								className="w-100"
								onChange={(e) => handleChange(e)}
							/>
						</label>
					</p>
					<p>
						<label>
							Your Email:{' '}
							<input
								type="email"
								name="email"
								className="w-100"
								onChange={(e) => handleChange(e)}
							/>
						</label>
					</p>
					<p>
						<label>
							Company:{' '}
							<input
								type="text"
								name="company"
								className="w-100"
								onChange={(e) => handleChange(e)}
							/>
						</label>
					</p>
					<p>
						Do you have any dietary requirements?{' '}
						<div class="radio-group">
							<label class="mr-2">
								Yes
								<input
									type="radio"
									name="dietary_requirements"
									value={true}
									onChange={(e) => handleChange(e)}
								/>
							</label>
							<label>
								No
								<input
									type="radio"
									name="dietary_requirements"
									value={false}
									onChange={(e) => handleChange(e)}
								/>
							</label>
						</div>
					</p>
					{formData.dietary_requirements && (
						<p>
							Please list the requirements:{' '}
							<label>
								<textarea
									rows="4"
									name="dietary_requirements_details"
									className="w-100 border-light-grey rsvp-text-area"
									onChange={(e) => handleChange(e)}
								/>
							</label>
						</p>
					)}
					<p>
						Do you have any accessibility requirements?{' '}
						<div class="radio-group">
							<label>
								Yes
								<input
									type="radio"
									name="accessibility_requirements"
									value={true}
									onChange={(e) => handleChange(e)}
								/>
							</label>
							<label>
								No
								<input
									type="radio"
									name="accessibility_requirements"
									value={false}
									onChange={(e) => handleChange(e)}
								/>
							</label>
						</div>
					</p>
					{formData.accessibility_requirements && (
						<p>
							<label>
								Please list the requirements:{' '}
								<textarea
									name="accessibility_requirements_details"
									rows="4"
									className="w-100 border-light-grey rsvp-text-area"
									onChange={(e) => handleChange(e)}
								/>
							</label>
						</p>
					)}
					{!success && (
						<p>
							<button type="submit">{sending ? 'Submitting' : 'Send'}</button>
						</p>
					)}
					{success && <p>Thank you for your submission</p>}
					{error && <p>Sorry there has been an error, please refresh</p>}
				</div>
			</form>
		</section>
	)
}

export default RsvpYes
