import React from 'react'

export default function MarkdownMedia({ props }) {
	const isVideo = props.src.endsWith('mp4') || props.src.endsWith('mov')

	return (
		<div className="mt-8">
			{isVideo ? (
				<video
					className="w-full"
					width="1000"
					controls
					src={props.node.properties.src}
				/>
			) : (
				<img src={props.node.properties.src} alt={props.alt} />
			)}
		</div>
	)
}
