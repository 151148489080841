import React, { useState } from 'react'
import { useEffect } from 'react'

const RsvpNo = ({ data }) => {
	/* Here’s the juicy bit for posting the form submission */

	const FORMSPARK_ACTION_URL = 'https://submit-form.com/RjC7uLxgG'

	const [sending, setSending] = useState(false)
	const [success, setSuccess] = useState(false)
	const [error, setError] = useState(false)

	const [formData, setFormData] = useState({
		cant_attend: false,
		email: '',
	})

	function handleChange(e) {
		const key = e.target.name
		let value = e.target.value

		if (key == 'cant_attend') {
			value = e.target.checked
		}

		setFormData((prev) => ({
			...prev,
			[key]: value,
		}))
	}

	function handleSubmit(e) {
		e.preventDefault()
		setSending(true)

		fetch(FORMSPARK_ACTION_URL, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(formData),
		})
			.then((res) => {
				setSending(false)
				setSuccess(true)
			})
			.then(() => {
				setTimeout(() => {
					window.location.href = '/'
				}, 4000)
			})
			.catch((err) => {
				setSending(false)
				setError(true)
				console.error('this is the error', err)
			})
	}

	return (
		<section
			data-section-id="news-index"
			className="m-spacing-top-20 border-top-light-grey"
		>
			<form onSubmit={(e) => handleSubmit(e)} class="gc">
				<input type="hidden" name="_email.subject" value="RSVP NO" />
				<div className="d-1-7 m-1-13 auto-layout">
					<h3>{data?.Title && data.Title}</h3>
				</div>
				<div className="d-7-12 m-1-13 auto-layout">
					<p>
						<label>
							No, I cant attend:{' '}
							<input
								type="checkbox"
								name="cant_attend"
								onChange={(e) => handleChange(e)}
							/>
						</label>
					</p>
					<p>
						<label>
							Your Email:{' '}
							<input
								type="email"
								name="email"
								className="w-100"
								onChange={(e) => handleChange(e)}
							/>
						</label>
					</p>
					<p>
						{!success && (
							<p>
								<button type="submit">{sending ? 'Submitting' : 'Send'}</button>
							</p>
						)}
						{success && <p>Thank you for your submission</p>}
						{error && <p>Sorry there has been an error, please refresh</p>}
					</p>
				</div>
			</form>
		</section>
	)
}

export default RsvpNo
